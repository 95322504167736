export const initialFormState = {
  cnpj: "",
  invoiced: null,
  solicitanteEmpresa: { value: "", disabled: false },
  solicitanteEmail: { value: "", disabled: false },
  solicitanteTelefone: { value: "", disabled: false },
  solicitanteEmailReserva: "",
  clienteNome: "",
  clienteCpf: "",
  clienteEmail: "",
  clienteTelefone: "",
  cep: "",
  Logradouro: "",
  Bairro: "",
  Cidade: "",
  Estado: "",
  Numero: "",
  Complemento: "",
  cidadeReserva: "",
  estadoReserva: "",
  placa: "",
  dataRetirada: "",
  horaRetirada: "",
  nomeResponsavel: "",
  cpfResponsavel: "",
  diarias: "",
  isRefatorado: 1,
  arquivos: {
    avisoSinistro: null,
    boletimOcorrencia: null,
    crlv: null,
    cnh: null,
    autorizacaoReparo: null,
  },
}
