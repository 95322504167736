import React, { useEffect } from "react";
import { pageTitle } from "../../helper";
import Cta from "../Cta";
import FunFact from "../FunFact";
import PageHeading from "../PageHeading";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import { basepath } from "../../App";
import Footer from "../Footer";
import { CustomCardInfo } from "../CustomCardInfo";
import { Link } from "react-router-dom";
import { SideMenu } from "../SideMenu";

export default function PequenosReparos() {
  pageTitle("Pequenos Reparos");

  const funfaceData = [
    {
      title: "Usuários no Clube de Descontos",
      factNumber: "2.5M",
    },
    {
      title: "Diárias de carro reserva liberadas todo o mês",
      factNumber: "15K",
    },
    {
      title: "Novos segurados de vida todo mês",
      factNumber: "1.4K",
    },
    {
      title: "Atendimentos de assistência residencial todo mês",
      factNumber: "33K",
    },
  ];

  const PRitems = [
    {
      id: 1,
      img: `${basepath}/img/gallery_images/pequenosreparos/peqreparos_7.webp`,
      title: "Reparo de parabrisas",
      description:
        "Ocorrendo a trinca do vidro do para-brisa em razão de acidentes ocorridos exclusivamente com o para-brisa, esta assistência garantirá o reparo do vidro do para-brisa quando possível de ser reparado.",
    },
    {
      id: 2,
      img: `${basepath}/img/gallery_images/pequenosreparos/peqreparos_3.webp`,
      title: "Higienização de ar condicionado",
      description:
        "Essa assistência, permite ao usuário a realização da limpeza do sistema de ar-condicionado, que é feita através de uma “bomba” bactericida que garante a eliminação dos fungos e bactérias presentes no sistema de refrigeração do veículo sem necessidade de desmonte do mesmo.",
    },
    {
      id: 3,
      img: `${basepath}/img/gallery_images/pequenosreparos/peqreparos_6.webp`,
      title: "Martelinho de ouro",
      description:
        "O serviço de Martelinho consiste em eliminar amassados na lataria de um veículo sem a utilização de lanternagem convencional de funilaria e pintura.",
    },
    {
      id: 4,
      img: `${basepath}/img/gallery_images/pequenosreparos/peqreparos_9.webp`,
      title: "Assistência a vidros",
      description:
        "Esta assistência, garantirá a substituição do vidro do parabrisa, do vidro traseiro ou dos vidros laterais do veículo em razão de acidentes ocorridos exclusivamente com o vidro.",
    },
    {
      id: 5,
      img: `${basepath}/img/gallery_images/pequenosreparos/peqreparos_8.webp`,
      title: "Assistência flr (farol, lanterna e retrovisores)",
      description:
        "Em caso de acidente, esse serviço garante o reparo ou a substituição de faróis e lanternas principais, dianteiras e traseiras, incluindo tecnologias de Xenônio e LED.",
    },
    {
      id: 6,
      img: `${basepath}/img/gallery_images/pequenosreparos/peqreparos_5.webp`,
      title: "Assistência de lataria e pintura (sem troca de peça)",
      description:
        "Esta assistência garantirá a proteção em caso de acidente, a mão-de-obra para a reparação de danos que tenham afetado a lataria do veículo em uma única ocorrência. Serão reparados os danos encontrados nas peças da lataria metálica plástica externa dos veículos, como portas, para-lamas, capô, teto e para-choques.",
    },
    {
      id: 7,
      img: `${basepath}/img/gallery_images/pequenosreparos/peqreparos_4.webp`,
      title: "Assistência contra buracos",
      description:
        "Em caso de impactos acidentais contra objetos cortantes, como blocos de sinalização de pista, buracos e desníveis acentuados, essa assistência garantirá o reparo de arranhões e substituição da roda, pneu e componentes do sistema de suspensão.",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageHeading
        title="Pequenos Reparos"
        bgSrc={`${basepath}/img/gallery_images/pequenosreparos/peqreparos_1.webp`}
        pageLinkText="Pequenos Reparos"
      />

      <SideMenu />

      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-7 mb-3">
            <SectionHeading
              title="Serviços de qualidade, ampla cobertura e melhor custo-benefício."
              subtitle="Sobre a assistência"
            >
              <Spacing lg="30" md="20" />
              <p className="text-justify cs-m0">
                O produto Pequenos Reparos garante ao associado em caso de
                eventual sinistro com seu veículo, o reparo dos mesmos. Com
                agilidade no atendimento, serviços de qualidade, ampla cobertura
                e melhor custo-benefício, possuímos coberturas para três tipo de
                veículos: “Passeio Nacional”, “Passeio Importado" e
                "Motocicleta".
              </p>
              <Spacing lg="20" md="20" />
              <Link to={`${basepath}/cliente/PRForm`}>
                <button
                  className="fade-in cs-btn cs-style1 cs-type1"
                  type="button"
                  onClick={null}
                >
                  <span>Solicite agora</span>
                </button>
              </Link>
              <Spacing lg="25" md="40" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-xl-2 mb-3">
            <img
              src={`${basepath}/img/gallery_images/pequenosreparos/peqreparos_2.webp`}
              alt="About"
              className="w-100 h-100 object-fit-cover cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
      </Div>

      <Div className="container">
        <h2 className="text-center cs-section_title mb-5"></h2>
        <Div className="row justify-content-center mt-5 pt-5">
          {PRitems.map((item) => (
            <Div key={item.id} className="col-md-4 mb-5">
              <CustomCardInfo data={item} />
            </Div>
          ))}
        </Div>
      </Div>

      <Div className="container">
        <FunFact
          title="Nossos numeros"
          subtitle="Conquistar um novo associado é muito bom,
          fidelizá-lo é muito melhor!"
          data={funfaceData}
        />
      </Div>

      <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1 h-100">
              <Div className="cs-image_layer_in h-100">
                <img
                  src={`${basepath}/img/gallery_images/gabi.webp`}
                  alt="About"
                  className="w-100 h-100 object-fit-cover cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading
              title="Por quê nos escolher:"
              subtitle="Por que nos escolher"
            >
              <Spacing lg="30" md="20" />
              <p className="text-justify cs-m0">
                Empresa especializada em oferecer serviços assistenciais e
                programa de benefícios para pessoas, empresas e Entidades de
                Autogestão no Brasil.
              </p>
              <Spacing lg="15" md="15" />
              <p className="text-justify cs-m0">
                Nossa missão é tornar a vida mais fácil, oferecendo serviços de
                excelência agregado aos nossos valores. "Não se preocupe, seu
                problema está em nossas mãos.
              </p>
              <Spacing lg="30" md="30" />
              <p className="text-justify">
                <strong>Missão: </strong>Nossa missão é tornar a vida mais
                fácil, oferecendo serviços de excelência agregado aos nossos
                valores.
              </p>
              <p className="text-justify">
                <strong>Visão: </strong>Ser a empresa de benefícios mais amada
                do Brasil
              </p>
              <p className="text-justify">
                <strong>Nossos valores: </strong>Honestidade: Agimos com
                coerência e verdade, Inovação: Gerar e implementar novas ideias,
                Lealdade: Manter a lealdade à empresa e seus interesses,
                Responsabilidade: Aceitamos as consequências de nossas ações,
                Confiabilidade: Mantemos sempre nossas promessas
              </p>
              <Div className="cs-separator cs-accent_bg"></Div>
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
            </SectionHeading>
          </Div>
        </Div>
      </Div>

      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Conquistar um novo associado é muito bom, fidelizá-lo é muito melhor!"
          btnText="Solicitar serviço!"
          btnLink={`${basepath}/cliente/PRForm`}
        />
      </Div>

      <Footer />
    </>
  );
}
