import { Route, Routes } from "react-router-dom";
import ContactPage from "./components/Pages/ContactPage";
import ErrorPage from "./components/Pages/ErrorPage";
import CaseStudyShowcaseHome from "./components/Pages/CaseStudyShowcaseHome";
import Layout from "./components/Layout";
import SeguroDeVida from "./components/Pages/SeguroVida";
import SeguroDeResidencial from "./components/Pages/SeguroResidencial";
import SeguroAp from "./components/Pages/SeguroAp";
import SeguroApp from "./components/Pages/SeguroApp";
import ClubeBeneficios from "./components/Pages/Clube";
import AutoConsulta from "./components/Pages/Consulta";
import BuscaVeiculos from "./components/Pages/BuscaVeiculos";
import Binrf from "./components/Pages/Binrf";
import Restricoes from "./components/Pages/Restricoes";
import Debito from "./components/Pages/Debito";
import Proprietarios from "./components/Pages/Proprietarios";
import Assistencia24 from "./components/Pages/Assistencia24";
import CarroReserva from "./components/Pages/CarroReserva";
import PequenosReparos from "./components/Pages/PequenosReparos";
import Vidro from "./components/Pages/Vidro";
import Pet from "./components/Pages/Pet";
import Residencial from "./components/Pages/Residencial";
import Bike from "./components/Pages/Bike";
import Telemedicina from "./components/Pages/Telemedicina";
import Funeral from "./components/Pages/Funeral";
import Odonto from "./components/Pages/Odonto";
import Rcv from "./components/Pages/Rcv";

export const basepath = "";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<CaseStudyShowcaseHome />} />

          <Route path="cliente" element={<ContactPage />} />

          <Route
            path="cliente/CRForm"
            element={<ContactPage currentPage="CR" />}
          />
          <Route
            path="cliente/APPForm"
            element={<ContactPage currentPage="APP" />}
          />
          <Route
            path="cliente/FForm"
            element={<ContactPage currentPage="FF" />}
          />
          <Route
            path="cliente/VForm"
            element={<ContactPage currentPage="VF" />}
          />
          <Route
            path="cliente/PForm"
            element={<ContactPage currentPage="PF" />}
          />
          <Route
            path="cliente/PRForm"
            element={<ContactPage currentPage="PR" />}
          />

          <Route path="seguro_de_vida" element={<SeguroDeVida />} />
          <Route path="seguro_residencial" element={<SeguroDeResidencial />} />
          <Route path="seguro_ap" element={<SeguroAp />} />
          <Route path="seguro_app" element={<SeguroApp />} />
          <Route path="seguro_rcv" element={<Rcv />} />

          <Route path="clube" element={<ClubeBeneficios />} />
          <Route path="autoconsulta" element={<AutoConsulta />} />

          <Route path="buscaveiculos" element={<BuscaVeiculos />} />
          <Route path="binrf" element={<Binrf />} />
          <Route path="restricoes" element={<Restricoes />} />
          <Route path="debito" element={<Debito />} />
          <Route path="proprietarios" element={<Proprietarios />} />

          <Route path="assistencia24" element={<Assistencia24 />} />
          <Route path="carroreserva" element={<CarroReserva />} />
          <Route path="vidro" element={<Vidro />} />
          <Route path="pequenosreparos" element={<PequenosReparos />} />

          <Route path="pet" element={<Pet />} />
          <Route path="residencial" element={<Residencial />} />
          <Route path="bike" element={<Bike />} />

          <Route path="telemedicina" element={<Telemedicina />} />
          <Route path="funeral" element={<Funeral />} />
          <Route path="odonto" element={<Odonto />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
