import React, { useEffect } from "react";
import { pageTitle } from "../../helper";
import Cta from "../Cta";
import FunFact from "../FunFact";
import PageHeading from "../PageHeading";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import { basepath } from "../../App";
import Footer from "../Footer";
import { SideMenu } from "../SideMenu";

export default function SeguroDeVida() {
  pageTitle("Seguro de Vida");

  const funfaceData = [
    {
      title: "Usuários no Clube de Descontos",
      factNumber: "2.5M",
    },
    {
      title: "Diárias de carro reserva liberadas todo o mês",
      factNumber: "15K",
    },
    {
      title: "Novos segurados de vida todo mês",
      factNumber: "1.4K",
    },
    {
      title: "Atendimentos de assistência residencial todo mês",
      factNumber: "33K",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHeading
        title="Seguro de Vida"
        bgSrc={`${basepath}/img/gallery_images/segurovida/segurovida_1.webp`}
        pageLinkText="Seguro de Vida"
      />

      <SideMenu />

      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-7 mb-3">
            <SectionHeading title="Viva em paz" subtitle="Sobre o seguro">
              <Spacing lg="30" md="20" />
              <p className="text-justify cs-m0">
                O Seguro de Vida é um produto que traz conforto e tranquilidade
                aos beneficiários do seu associado nos momentos difíceis. Ao
                contratar esse produto o associado estará coberto em caso de
                morte natural, morte acidental ou invalidez por acidente. São
                disponibilizados planos com capital de cobertura a partir de
                R$5.000 até R$20.000 em caso de evento coberto.
                <br />
                Em pareceria com a MBM Seguradora somos estipulantes de apólice
                coletiva nº 18-0993-004332-0001 do produto aprovado na SUSEP
                sobre os seguintes números: PROCESSO SUSEP 10.004809/99-87 e
                PROCESSO SUSEP 15414.901229/2016-44.
              </p>
              <Spacing lg="30" md="30" />
              <p className="text-justify">
                Contratação sem necessidade de Declaração Pessoal de Saúde;
                Indenização paga aos beneficiários em até 30 dias após entregue
                toda a documentação; Preços bem inferiores aos praticados pelo
                mercado tradicional
              </p>
              <Spacing lg="30" md="30" />
              <Div className="text-justify cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-xl-2 mb-3">
            <img
              src={`${basepath}/img/gallery_images/segurovida/segurovida_2.webp`}
              alt="About"
              className="w-100 h-100 object-fit-cover cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="hide_on_mobile col-lg-7">
            <img
              src={`${basepath}/img/gallery_images/segurovida/segurovida_3.webp`}
              alt="About"
              className="w-100 h-100 object-fit-cover cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="hide_on_mobile col-lg-5">
            <img
              src={`${basepath}/img/gallery_images/segurovida/segurovida_4.webp`}
              alt="About"
              className="w-100 h-100 object-fit-cover cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
      </Div>
      <Spacing lg="75" md="55" />

      <Div className="container">
        <FunFact
          title="Nossos numeros"
          subtitle="Conquistar um novo associado é muito bom,
          fidelizá-lo é muito melhor!"
          data={funfaceData}
        />
      </Div>

      <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1 h-100">
              <Div className="cs-image_layer_in h-100">
                <img
                  src={`${basepath}/img/gallery_images/gabi.webp`}
                  alt="About"
                  className="w-100 h-100 object-fit-cover cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading
              title="Por quê nos escolher:"
              subtitle="Por que nos escolher"
            >
              <Spacing lg="30" md="20" />
              <p className="text-justify cs-m0">
                Empresa especializada em oferecer serviços assistenciais e
                programa de benefícios para pessoas, empresas e Entidades de
                Autogestão no Brasil.
              </p>
              <Spacing lg="15" md="15" />
              <p className="text-justify cs-m0">
                Nossa missão é tornar a vida mais fácil, oferecendo serviços de
                excelência agregado aos nossos valores. "Não se preocupe, seu
                problema está em nossas mãos.
              </p>
              <Spacing lg="30" md="30" />
              <p className="text-justify">
                <strong>Missão: </strong>Nossa missão é tornar a vida mais
                fácil, oferecendo serviços de excelência agregado aos nossos
                valores.
              </p>
              <p className="text-justify">
                <strong>Visão: </strong>Ser a empresa de benefícios mais amada
                do Brasil
              </p>
              <p className="text-justify">
                <strong>Nossos valores: </strong>Honestidade: Agimos com
                coerência e verdade, Inovação: Gerar e implementar novas ideias,
                Lealdade: Manter a lealdade à empresa e seus interesses,
                Responsabilidade: Aceitamos as consequências de nossas ações,
                Confiabilidade: Mantemos sempre nossas promessas
              </p>
              <Div className="cs-separator cs-accent_bg"></Div>
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
            </SectionHeading>
          </Div>
        </Div>
      </Div>

      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Conquistar um novo associado é muito bom, fidelizá-lo é muito melhor!"
          btnText="Solicitar serviço!"
        />
      </Div>

      <Footer />
    </>
  );
}
