import React from "react";
import Div from "../Div";
import FullScreenVerticalSlider from "../Slider/FullScreenVerticalSlider";

export default function Hero7({
  socialLinksHeading,
  heroSocialLinks,
  showcaseData,
}) {
  return (
    <Div className="cs-hero_7_wrap">
      <FullScreenVerticalSlider data={showcaseData} />
    </Div>
  );
}
