import { api } from "../utils/axios";

const handleType = (type) => {
  switch (type) {
    case "Automóvel":
      return "car";
    case "Caminhão":
      return "truck";
    case "Ônibus":
      return "bus";
    default:
      return "Não identificado.";
  }
};

const handleGlassModel = (glass_model) => {
  switch (glass_model) {
    case "Simples":
      return "simple";
    case "Grade":
      return "grid";
    default:
      return "Não identificado.";
  }
};

const handleDamagedPart = (damaged_part) => {
  switch (damaged_part) {
    case "Vidro Traseiro":
      return "rear-window";
    case "Retrovisor":
      return "rearview-mirror";
    case "ParaBrisa":
      return "windshield";
    case "Janelas Laterais":
      return "side-windows";
    case "Farois":
      return "headlights";
    case "Lanterna traseira":
      return "taillight";
    default:
      return "Não identificado.";
  }
};

export async function sendGlass(formData) {
  const formDataObj = Object.fromEntries(formData);

  const formDataBody = new FormData();

  formDataBody.append("plate", formDataObj.Placa);
  formDataBody.append("chassi", formDataObj.Chassi);
  formDataBody.append("sensor", formDataObj.Sensor === "Sim" ? 1 : 0);
  formDataBody.append("damaged_part", handleDamagedPart(formDataObj.Peca));
  formDataBody.append("glass_model", handleGlassModel(formDataObj.ModeloVidro));
  formDataBody.append("description", formDataObj.DescricaoSinistro);
  formDataBody.append("company[name]", formDataObj.SolicitanteEmpresa);
  formDataBody.append("company[cnpj]", formDataObj.CNPJ.replace(/[./-]/g, ""));
  formDataBody.append("company[email]", formDataObj.EmailEmpresa);
  formDataBody.append("company[phone]", formDataObj.TelefoneEmpresa);
  formDataBody.append("beneficiary[name]", formDataObj.Nome);
  formDataBody.append("beneficiary[email]", formDataObj.Email);
  formDataBody.append("beneficiary[cpf]", formDataObj.CPF.replace(/[.-]/g, ""));
  formDataBody.append("beneficiary[phone]", formDataObj.Telefone);
  formDataBody.append("address[state]", formDataObj.Estado);
  formDataBody.append("address[city]", formDataObj.Cidade);
  formDataBody.append("vehicle[type]", handleType(formDataObj.Tipo));
  formDataBody.append("vehicle[brand]", formDataObj.Fabricante);
  formDataBody.append("vehicle[year]", formDataObj.AnoFabricacao);
  formDataBody.append("vehicle[model][year]", formDataObj.AnoModelo);
  formDataBody.append("vehicle[model][name]", formDataObj.modelo);
  formDataBody.append("attachments[0][type]", "company-claim-notice");
  formDataBody.append("attachments[0][file]", formDataObj.Anexo1);
  formDataBody.append("attachments[1][type]", "damaged-glass");
  formDataBody.append("attachments[1][file]", formDataObj.Anexo2);
  formDataBody.append("attachments[2][type]", "prior-vehicle-inspection");
  formDataBody.append("attachments[2][file]", formDataObj.Anexo3);

  await api.post("/landing/glass", formDataBody, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
