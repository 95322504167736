import { api } from "../utils/axios";

const handleUtilization = (utilization) => {
  switch (utilization) {
    case "Motorista de Aplicativo":
      return "app-driver";
    case "Passeio":
      return "tour";
    case "Taxi":
      return "taxi";
    case "Transporte de passageiro":
      return "passenger-transport";
    case "Locaçao":
      return "rental";
    default:
      return "Não identificado.";
  }
};

const handleType = (type) => {
  switch (type) {
    case "Automóvel":
      return "car";
    case "Caminhão":
      return "truck";
    case "Ônibus":
      return "bus";
    case "Micro-ônibus/Va":
      return "minibus-or-ovan";
    default:
      return "Não identificado.";
  }
};

const handleCoverage = (coverage) => {
  switch (coverage) {
    case "Morte Acidental (MA)":
      return "accidental-death";
    case "Invalidez Permanente por Acidente (IPA)":
      return "permanent-disability-due-to-accident";
    case "MA e IPA":
      return "ma-ipa";
    case "MA e DMH":
      return "ma-dmh";
    case "IPA e DMH":
      return "ipa-dmh";
    case "MA, IPA e DMH":
      return "ma-ipa-dmh";
    default:
      return "Não identificado.";
  }
};

export async function sendAppInsurence(formData, type) {
  const formDataObj = Object.fromEntries(formData);
  const formDataBody = new FormData();

  if (type === "request") {
    await api.post("/landing/app-insurance", {
      type: type,
      plate: formDataObj.Placa,
      chassi: formDataObj.Chassi,
      renavam: formDataObj.Renavam,
      maximum_capacity: formDataObj.LotacaoMaxima,
      insured_capital: parseFloat(
        formDataObj.CapitalSegurado.replace(/[R$.]/g, "").replace(",", ".")
      ),
      utilization: handleUtilization(formDataObj.Utilizacao),
      coverage: handleCoverage(formDataObj.Cobertura),
      company: {
        name: formDataObj.SolicitanteEmpresa,
        cnpj: formDataObj.CNPJEmpresa.replace(/[./-]/g, ""),
        phone: formDataObj.TelefoneEmpresa,
        email: formDataObj.EmailEmpresa,
      },
      beneficiary: {
        name: formDataObj.Nome,
        email: formDataObj.Email,
        cpf: formDataObj.CPF.replace(/[.-]/g, ""),
        rg: formDataObj.RG.replace(/[ -]/g, ""),
        phone: formDataObj.TelefoneComercial,
      },
      secondary_driver: {
        name: formDataObj.Nome2,
        email: formDataObj.Email2,
        cpf: formDataObj.CPF2 && formDataObj.CPF2.replace(/[.-]/g, ""),
        phone: formDataObj.Telefone2,
      },
      address: {
        zip_code: formDataObj.CEP,
        state: formDataObj.UF,
        city: formDataObj.Cidade,
        neighborhood: formDataObj.Bairro,
        street: formDataObj.Logradouro,
        number: formDataObj.Numero,
        complement: formDataObj.Complemento,
      },
      vehicle: {
        type: handleType(formDataObj.Categoria),
        brand: formDataObj.Montadora,
        year: formDataObj.Ano,
        model: {
          year: formDataObj.Ano,
          name: formDataObj.Modelo,
        },
      },
    });
  } else {
    formDataBody.append("type", type);
    formDataBody.append("reason", formDataObj.reason);
    formDataBody.append(
      "company[cnpj]",
      formDataObj.cnpj.replace(/[./-]/g, "")
    );
    formDataBody.append("company[email]", formDataObj.email);
    formDataBody.append("plate", formDataObj.plate);
    formDataBody.append("company[name]", formDataObj.empresa);
    formDataBody.append("company[phone]", formDataObj.phone);
    formDataBody.append("attachments[0][type]", "death-certificate");
    formDataBody.append("attachments[0][file]", formDataObj.death);
    formDataBody.append("attachments[1][type]", "bo");
    formDataBody.append("attachments[1][file]", formDataObj.bo);
    formDataBody.append("attachments[2][type]", "dut");
    formDataBody.append("attachments[2][file]", formDataObj.dut);
    formDataBody.append("attachments[3][type]", "cpf");
    formDataBody.append("attachments[3][file]", formDataObj.cpf);
    formDataBody.append("attachments[4][type]", "medical-certificate");
    formDataBody.append("attachments[4][file]", formDataObj.medical);

    await api.post("/landing/app-insurance", formDataBody, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}
