import { useState } from "react"
import { toast } from "react-toastify"

export function useFetchCnpj(dispatch) {
  const [isSearchingCnpj, setIsSearchingCnpj] = useState(false)

  const handleCnpjSearch = async (cnpjValue) => {
    if (!cnpjValue || cnpjValue.length !== 18) {
      toast.error(
        "CNPJ inválido. Certifique-se de que está no formato correto."
      )
      return
    }

    setIsSearchingCnpj(true)
    try {
      const response = await fetch(
        `https://api.virtual.brasilbeneficios.club/api/landing/company?cnpj=${cnpjValue.replace(
          /[.\-\/]/g,
          ""
        )}`
      )
      const data = await response.json()

      if (!data.name) {
        toast.error("Empresa não encontrada.")
        return
      }

      // Atualiza os campos no estado global ou local
      dispatch({
        type: "UPDATE_FIELD",
        field: "solicitanteEmpresa",
        value: data.name,
        disabled: true,
      })
      dispatch({
        type: "UPDATE_FIELD",
        field: "solicitanteEmail",
        value: data.email,
        disabled: true,
      })
      dispatch({
        type: "UPDATE_FIELD",
        field: "solicitanteTelefone",
        value: data.phone,
        disabled: true,
      })
      dispatch({
        type: "UPDATE_FIELD",
        field: "invoiced",
        value: !!data.invoiced,
      })
    } catch (error) {
      toast.error("Erro ao buscar informações do CNPJ.")
      console.error(error)
    } finally {
      setIsSearchingCnpj(false)
    }
  }

  return { handleCnpjSearch, isSearchingCnpj }
}
