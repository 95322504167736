import { api } from "../utils/axios";

const handleType = (type) => {
  switch (type) {
    case "Multi-Familiar":
      return "multi-family";
    case "Familiar":
      return "family";
    case "Individual":
      return "individual";
    default:
      return "Não identificado.";
  }
};

export async function sendFuneral(formData) {
  const formDataObj = Object.fromEntries(formData);

  const formDataBody = new FormData();

  formDataBody.append("company[name]", formDataObj.SolicitanteEmpresa);
  formDataBody.append("company[cnpj]", formDataObj.cnpj.replace(/[./-]/g, ""));
  formDataBody.append("company[email]", formDataObj.EmailEmpresa);
  formDataBody.append("company[phone]", formDataObj.TelefoneEmpresa);
  formDataBody.append("beneficiary[name]", formDataObj.Nome);
  formDataBody.append("beneficiary[email]", formDataObj.Email);
  formDataBody.append("beneficiary[cpf]", formDataObj.CPF.replace(/[.-]/g, ""));
  formDataBody.append("beneficiary[phone]", formDataObj.Telefone);
  formDataBody.append(
    "insured_capital",
    formDataObj.CapitalSegurado.replace(/[R$.]/g, "").replace(",", ".")
  );
  formDataBody.append("type", handleType(formDataObj.Tipo));
  formDataBody.append("attachments[0][type]", "death-certificate");
  formDataBody.append("attachments[0][file]", formDataObj.Anexo1);
  formDataBody.append("attachments[1][type]", "beneficiary-rg-cpf");
  formDataBody.append("attachments[1][file]", formDataObj.Anexo2);
  formDataBody.append("attachments[2][type]", "holder-rg-cpf");
  formDataBody.append("attachments[2][file]", formDataObj.Anexo3);
  formDataBody.append("attachments[3][type]", "bank-proof");
  formDataBody.append("attachments[3][file]", formDataObj.Anexo4);
  formDataBody.append("attachments[4][type]", "tax-note");
  formDataBody.append("attachments[4][file]", formDataObj.Anexo5);

  await api.post("/landing/funeral", formDataBody, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
