import { useState } from "react";
import { toast } from "react-toastify";

export function useFetchCep(dispatch) {
  const [isSearchingCep, setIsSearchingCep] = useState(false);

  const handleCepSearch = async (cep) => {
    if (!cep || cep.length !== 9) {
      toast.error(
        "CEP inválido. Certifique-se de que está no formato correto."
      );
      return;
    }

    setIsSearchingCep(true);
    try {
      const response = await fetch(
        `https://viacep.com.br/ws/${cep.replace("-", "")}/json`
      );
      const data = await response.json();

      if (data.erro) {
        toast.error("CEP não encontrado.");
        return;
      }

      // Atualiza os campos com base nos dados do CEP
      dispatch({
        type: "UPDATE_FIELD",
        field: "Logradouro",
        value: data.logradouro,
      });
      dispatch({ type: "UPDATE_FIELD", field: "Bairro", value: data.bairro });
      dispatch({
        type: "UPDATE_FIELD",
        field: "Cidade",
        value: data.localidade,
      });
      dispatch({ type: "UPDATE_FIELD", field: "Estado", value: data.uf });
    } catch (error) {
      toast.error("Erro ao buscar informações do CEP.");
      console.error(error);
    } finally {
      setIsSearchingCep(false);
    }
  };

  return { handleCepSearch, isSearchingCep };
}
