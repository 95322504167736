import React from "react";
import Div from "../Div";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import SocialWidget from "../Widget/SocialWidget";
import "./footer.scss";

export default function Footer({ copyrightText, logoSrc, logoAlt, text }) {
  return (
    <footer className="cs-fooer">
      <Div className="cs-fooer_main">
        <Div className="container">
          <Div className="row justify-content-center">
            <Div className="col-lg-8">
              <Div className="cs-footer_item">
                <SocialWidget />
                <ContactInfoWidget />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Div className="container">
        <Div className="cs-bottom_footer">
          <Div className="cs-bottom_footer_left">
            <Div className="cs-copyright">
              Copyright © 2025 Brasil Beneficios - Desenvolvido por{" "}
              <a
                href="https://devandrew.com.br"
                target="_blank"
                rel="noreferrer"
              >
                DevAndrew | Agência Brasil Digital
              </a>
            </Div>
          </Div>
        </Div>
      </Div>
    </footer>
  );
}
