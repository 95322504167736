export function formReducer(state, action) {
  switch (action.type) {
    case "UPDATE_FIELD":
      return {
        ...state,
        [action.field]:
          action.field === "invoiced"
            ? action.value
            : {
                value: action.value,
                disabled:
                  action.disabled ?? state[action.field]?.disabled ?? false,
              },
      }
    case "UPDATE_FILES":
      return {
        ...state,
        arquivos: { ...state.arquivos, [action.fileType]: action.file },
      }
    default:
      return state
  }
}
