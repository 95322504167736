import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react"
import Div from "../Div"
import { useFetchStates } from "./hooks/useFetchStates"
import { useFetchCities } from "./hooks/useFetchCities"
import { useFetchCep } from "./hooks/useFetchCep"
import { useFetchCnpj } from "./hooks/useFetchCnpj"
import { SuccessMessage } from "./CarroReserva/SucessMessage"
import { initialFormState } from "./CarroReserva/InitialFormState"
import { formReducer } from "./CarroReserva/FormReducer"
import { handleSubmit } from "./CarroReserva/HandleSubmit"
import { RenderFormFields } from "./CarroReserva/FormFields"

// Contexto para o estado do formulário
const FormContext = createContext()

export function useFormContext() {
  const context = useContext(FormContext)
  if (!context) {
    throw new Error("useFormContext must be used within a FormProvider")
  }
  return context
}

export function CarroReservaForm() {
  const [sendingButton, setSendingButton] = useState("Solicitar benefício")
  const [carroReservaStatus, setCarroReservaStatus] = useState(null)
  const [formState, dispatch] = useReducer(formReducer, initialFormState)

  const { states, fetchStates } = useFetchStates()
  const { cities, fetchCities, isSearchingCity } = useFetchCities()
  const { handleCepSearch, isSearchingCep } = useFetchCep(dispatch)
  const { handleCnpjSearch, isSearchingCnpj } = useFetchCnpj(dispatch)

  useEffect(() => {
    fetchStates()
  }, [])

  const handleFormSubmit = (event) =>
    handleSubmit(
      event,
      formState,
      dispatch,
      setSendingButton,
      setCarroReservaStatus
    )

  return (
    <FormContext.Provider value={{ formState, dispatch }}>
      {carroReservaStatus === "Sended" ? (
        SuccessMessage()
      ) : (
        <Div className="fade-in col-lg-6">
          <form
            onSubmit={handleFormSubmit}
            method="post"
            encType="multipart/form-data"
          >
            <RenderFormFields
              handleCnpjSearch={handleCnpjSearch}
              handleCepSearch={handleCepSearch}
              states={states}
              cities={cities}
              fetchCities={fetchCities}
              isSearchingCity={isSearchingCity}
              isSearchingCnpj={isSearchingCnpj}
              isSearchingCep={isSearchingCep}
            />
            <button
              className="fade-in cs-btn cs-style1 cs-type1 mt-4"
              type="submit"
            >
              <span>{sendingButton}</span>
            </button>
          </form>
        </Div>
      )}
    </FormContext.Provider>
  )
}
