export function CustomCardInfo({ data, buttonText, cardLink }) {
  return (
    <div className="card_wrapp">
      <div className="header">
        <img src={data.img} alt="" />
        <h5>{data.title}</h5>
        <p className="text-justify">{data.description}</p>
      </div>
    </div>
  );
}
