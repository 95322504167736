import { CheckCircle } from "phosphor-react"
import Div from "../../Div"

export const SuccessMessage = () => (
  <Div>
    <CheckCircle size={50} />
    <h5 className="mt-3" style={{ fontSize: 24 }}>
      Sucesso!
    </h5>
    <p>
      Sua solicitação foi realizada com sucesso!
      <br />
      Em breve entraremos em contato para fazer a liberação do seu carro
      reserva!
    </p>
    <button
      className="fade-in cs-btn cs-style1 cs-type1"
      style={{ marginTop: 16 }}
      type="button"
      onClick={() => window.location.reload()}
    >
      <span>Realizar outra solicitação</span>
    </button>
  </Div>
)
