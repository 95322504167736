import { api } from "../utils/axios"

export async function sendReserve(formData, CRisRefatorado) {
  console.log(CRisRefatorado)
  const formDataObj = Object.fromEntries(formData)

  const formDataBody = new FormData()

  formDataBody.append("plate", formDataObj.Placa)
  formDataBody.append("days", formDataObj.QuantidadeDeDiarias)
  formDataBody.append("company[name]", formDataObj.solicitanteEmpresa)
  formDataBody.append(
    "company[cnpj]",
    formDataObj.CNPJEmpresa.replace(/[./-]/g, "")
  )
  formDataBody.append("company[email]", formDataObj.solicitanteEmail)
  formDataBody.append("company[phone]", formDataObj.solicitanteTelefone)
  formDataBody.append("responsible[email]", formDataObj.solicitanteEmailReserva)
  formDataBody.append("beneficiary[name]", formDataObj.Nome)
  formDataBody.append("beneficiary[email]", formDataObj.Email)
  formDataBody.append("beneficiary[cpf]", formDataObj.CPF.replace(/[.-]/g, ""))
  formDataBody.append("beneficiary[phone]", formDataObj.Telefone)
  formDataBody.append("beneficiary[address][zip_code]", formDataObj.CEP)
  formDataBody.append("beneficiary[address][street]", formDataObj.Logradouro)
  formDataBody.append("beneficiary[address][neighborhood]", formDataObj.Bairro)
  formDataBody.append("beneficiary[address][city]", formDataObj.Cidade)
  formDataBody.append("beneficiary[address][state]", formDataObj.Estado)
  formDataBody.append("beneficiary[address][number]", formDataObj.Numero)
  formDataBody.append(
    "beneficiary[address][complement]",
    formDataObj.Complemento
  )
  formDataBody.append("address[state]", formDataObj.EstadoRetirada)
  formDataBody.append("address[city]", formDataObj.CidadeRetirada)
  formDataBody.append("withdrawn[date]", formDataObj.DataRetirada)
  formDataBody.append("withdrawn[hour]", formDataObj.HoraRetirada)
  formDataBody.append("responsible[name]", formDataObj.NomeResponsavelCartao)
  formDataBody.append(
    "responsible[cpf]",
    formDataObj.CPFResponsavelCartao.replace(/[.-]/g, "")
  )

  if (CRisRefatorado === 0) {
    formDataBody.append("attachments[0][type]", "bo")
    formDataBody.append("attachments[0][file]", formDataObj.boletimOcorrencia)
    formDataBody.append("attachments[1][type]", "cnh")
    formDataBody.append("attachments[1][file]", formDataObj.cnh)
    formDataBody.append("attachments[2][type]", "claim-notice")
    formDataBody.append("attachments[2][file]", formDataObj.avisoSinistro)
    formDataBody.append("attachments[3][type]", "car-document")
    formDataBody.append("attachments[3][file]", formDataObj.crlv)
    formDataBody.append("attachments[4][type]", "workshop-report")
    formDataBody.append("attachments[4][file]", formDataObj.autorizacaoReparo)
  }

  await api.post("/landing/reserve-car", formDataBody, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}
