import { toast } from "react-toastify"
import { sendReserve } from "../../../api/send-reserve"

export const handleSubmit = async (
  event,
  formState,
  dispatch,
  setSendingButton,
  setCarroReservaStatus
) => {
  event.preventDefault()
  setSendingButton("Enviando...")

  const regexCNPJ = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/
  const regexTelefone = /^\(?\d{2}\)? ?(?:\d ?)?\d{4}-?\d{4}$/
  const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const regexCPF = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/
  const regexCEP = /^\d{5}-\d{3}$/
  const regexPlaca = /^[a-zA-Z]{3}-\d{1}[a-zA-Z0-9]{3}$/
  const regexData = /^\d{2}\/\d{2}\/\d{4}$/
  const regexHora = /^\d{2}:\d{2}$/

  if (!regexCNPJ.test(formState.cnpj.value)) {
    toast.error("O campo CNPJ está inválido ou não preenchido.")
    setSendingButton("Solicitar benefício")
    return
  }

  if (!regexTelefone.test(formState.solicitanteTelefone.value)) {
    toast.error("O campo telefone do responsável está inválido.")
    setSendingButton("Solicitar benefício")
    return
  }

  if (!regexEmail.test(formState.solicitanteEmail.value)) {
    toast.error("O campo email do responsável está inválido.")
    setSendingButton("Solicitar benefício")
    return
  }

  if (!regexEmail.test(formState.clienteEmail.value)) {
    toast.error("O campo email do cliente está inválido.")
    setSendingButton("Solicitar benefício")
    return
  }

  if (!regexEmail.test(formState.solicitanteEmailReserva.value)) {
    toast.error("O campo email da reserva está inválido.")
    setSendingButton("Solicitar benefício")
    return
  }

  if (!regexCPF.test(formState.clienteCpf.value)) {
    toast.error("O campo CPF do cliente está inválido.")
    setSendingButton("Solicitar benefício")
    return
  }

  if (!regexCPF.test(formState.cpfResponsavel.value)) {
    toast.error("O campo CPF do responsável está inválido.")
    setSendingButton("Solicitar benefício")
    return
  }

  if (!regexCEP.test(formState.cep.value)) {
    toast.error("O campo CEP está inválido ou não preenchido.")
    setSendingButton("Solicitar benefício")
    return
  }

  if (!formState.Logradouro.value) {
    toast.error("O campo logradouro é obrigatório.")
    setSendingButton("Solicitar benefício")
    return
  }

  if (!formState.Bairro.value) {
    toast.error("O campo bairro é obrigatório.")
    setSendingButton("Solicitar benefício")
    return
  }

  if (!formState.Cidade.value) {
    toast.error("O campo cidade é obrigatório.")
    setSendingButton("Solicitar benefício")
    return
  }

  if (!formState.Estado.value) {
    toast.error("O campo estado é obrigatório.")
    setSendingButton("Solicitar benefício")
    return
  }

  if (!formState.Numero.value) {
    toast.error("O campo número é obrigatório.")
    setSendingButton("Solicitar benefício")
    return
  }

  if (!formState.Complemento.value) {
    toast.error("O campo complemento é obrigatório.")
    setSendingButton("Solicitar benefício")
    return
  }

  if (!regexPlaca.test(formState.placa.value)) {
    toast.error("O campo placa está inválido.")
    setSendingButton("Solicitar benefício")
    return
  }

  if (!formState.estadoReserva.value) {
    toast.error("O campo estado de retirada é obrigatório.")
    setSendingButton("Solicitar benefício")
    return
  }

  if (!formState.cidadeReserva.value) {
    toast.error("O campo cidade de retirada é obrigatório.")
    setSendingButton("Solicitar benefício")
    return
  }

  if (!regexData.test(formState.dataRetirada.value)) {
    toast.error("O campo data de retirada está inválido.")
    setSendingButton("Solicitar benefício")
    return
  }

  if (!regexHora.test(formState.horaRetirada.value)) {
    toast.error("O campo hora de retirada está inválido.")
    setSendingButton("Solicitar benefício")
    return
  }

  if (!formState.diarias.value || formState.diarias.value < 1) {
    toast.error("O campo diárias é obrigatório e deve ser no mínimo 1.")
    setSendingButton("Solicitar benefício")
    return
  }

  if (formState.isRefatorado === 0) {
    const arquivos = [
      { field: "avisoSinistro", name: "Aviso de Sinistro" },
      { field: "boletimOcorrencia", name: "Boletim de Ocorrência" },
      { field: "crlv", name: "CRLV do Veículo" },
      { field: "cnh", name: "CNH" },
      { field: "autorizacaoReparo", name: "Autorização de Reparo" },
    ]

    for (const { field, name } of arquivos) {
      if (!formState.arquivos[field]) {
        toast.error(`O campo ${name} é obrigatório.`)
        setSendingButton("Solicitar benefício")
        return
      }
    }
  }

  const formData = new FormData(event.currentTarget)

  if (formState.solicitanteTelefone.disabled) {
    formData.append("solicitanteTelefone", formState.solicitanteTelefone.value)
  }

  if (formState.solicitanteEmail.disabled) {
    formData.append("solicitanteEmail", formState.solicitanteEmail.value)
  }

  if (formState.solicitanteEmpresa.disabled) {
    formData.append("solicitanteEmpresa", formState.solicitanteTelefone.value)
  }

  try {
    await sendReserve(formData, formState.isRefatorado)
    toast.success(
      "Solicitação enviada com sucesso. Em breve, nossa equipe entrará em contato!"
    )
    setCarroReservaStatus("Sended")
  } catch {
    toast.error(
      "Algo deu errado. Confira os campos e tente novamente, ou fale com nossos especialistas."
    )
  } finally {
    setSendingButton("Solicitar benefício")
  }
}
