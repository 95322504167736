import Div from "../Div"
import Spacing from "../Spacing"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { CheckCircle } from "phosphor-react"
import InputMask from "react-input-mask"
import { useSolicitationStorage } from "../CheckPreviousSolicitationContext"
import { FileInputPreview } from "./FileInputPreview"
import { Loading } from "../loading"
import { sendGlass } from "../../api/send-glass"

export function VidrosForm() {
  const { keepLastVidros } = useSolicitationStorage()
  const [sendingButton, setSendingButton] = useState("Solicitar benefício")
  const [vidrosStatus, setvidrosStatus] = useState(null)

  const [isSearchingCity, setIsSearchingCity] = useState(false)
  const [isSearchingVehicule, setIsSearchingVehicule] = useState(false)
  const [isSearchingCnpj, setIsSearchingCnpj] = useState(false)

  const [eidField, setEidField] = useState(null)

  const [vidroscnpj, setvidrosCnpj] = useState(null)
  const [vidrossolicitante, setvidrosSolicitante] = useState(null)
  const [vidrostelefone, setvidrosTelefone] = useState(null)
  const [vidrosemail, setvidrosEmail] = useState(null)

  const [vidrosnomesolicitante, setvidrosnomesolicitante] = useState(null)
  const [vidroscpfsolicitante, setvidroscpfsolicitante] = useState(null)
  const [vidrosemailsolicitante, setvidrosemailsolicitante] = useState(null)
  const [vidrostelefonesolicitante, setvidrostelefonesolicitante] =
    useState(null)

  const [vidrosplaca, setvidrosplaca] = useState(null)
  const [vidroschassi, setvidroschassi] = useState(null)
  const [vidrostipo, setvidrostipo] = useState(null)
  const [vidrosmarca, setvidrosmarca] = useState([])
  const [vidrosmodelo, setvidrosmodelo] = useState([])
  const [modeloSelecionado, setModeloSelecionado] = useState({
    label: "",
    value: "",
  })
  const [marcaSelecionada, setMarcaSelecionado] = useState({
    label: "",
    value: "",
  })

  const [vidrosanofabricacao, setvidrosanofabricacao] = useState(null)
  const [vidrosanomodelo, setvidrosanomodelo] = useState(null)
  const [vidrosestado, setvidrosestado] = useState([])
  const [vidroscidade, setvidroscidade] = useState([])
  const [vidrospecadanificada, setvidrospecadanificada] = useState(null)
  const [vidrospossuisensor, setvidrospossuisensor] = useState(null)
  const [vidrosmodelovidro, setvidrosmodelovidro] = useState(null)
  const [vidrosdescricaosinistro, setvidrosdescricaosinistro] = useState(null)

  const [vidrosavisodesinistro, setvidrosavisodesinistro] = useState()
  const [vidrosfotosvidro, setvidrosfotosvidro] = useState()
  const [vidrosfotovistoriaprevia, setvidrosfotovistoriaprevia] = useState()

  const fetchMarcas = async () => {
    await fetch("https://nvo.brasilbeneficios.club/api/get-marcas")
      .then((res) => res.json())
      .then((data) => {
        setvidrosmarca(data.data)
      })
  }

  function handleMarcaChange(e) {
    setIsSearchingVehicule(true)

    const selectedBrand = vidrosmarca.find(
      (brand) => brand.Value === e.target.value
    )
    if (selectedBrand) {
      setMarcaSelecionado({
        label: selectedBrand.Label,
        value: selectedBrand.Value,
      })
    }

    fetch(`https://nvo.brasilbeneficios.club/api/get-modelos/${e.target.value}`)
      .then((res) => res.json())
      .then((data) => {
        setvidrosmodelo(data.data.Modelos)
      })
      .finally(() => setIsSearchingVehicule(false))
  }

  function handleModeloChange(e) {
    const selectedModel = vidrosmodelo.find(
      (model) => model.Value.toString() === e.target.value
    )

    if (selectedModel) {
      setModeloSelecionado({
        label: selectedModel.Label,
        value: selectedModel.Value,
      })
    }
  }

  const fetchStates = async () => {
    await fetch("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
      .then((res) => res.json())
      .then((data) => setvidrosestado(data))
  }

  function handleStateChange(e) {
    setIsSearchingCity(true)
    fetch(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${e.target.value}/municipios`
    )
      .then((res) => res.json())
      .then((data) => setvidroscidade(data))
      .finally(() => setIsSearchingCity(false))
  }

  const handleVidrosSubmit = async (event) => {
    event.preventDefault()
    setSendingButton("Enviando...")

    const formData = new FormData(event.currentTarget)

    formData.set("Fabricante", marcaSelecionada.label)
    formData.set("modelo", modeloSelecionado.label)

    await sendGlass(formData)

    fetch("https://brasilbeneficios.club/bb/area-cliente/vidros/send", {
      method: "POST",
      mode: "no-cors",
      credentials: "same-origin",
      body: formData,
    }).then((res) => {
      toast.success(
        "Solicitação enviada com sucesso. Em breve, nossa equipe entrará em contato!"
      )

      for (const data of formData.entries()) {
        localStorage.setItem(data[0], data[1])
      }

      setvidrosStatus("Sended")
      localStorage.setItem("lastSolicitatedService", "Vidros")
    })
  }

  useEffect(() => {
    fetchStates()
    fetchMarcas()
  }, [])

  const handleCNPJ = () => {
    if (vidroscnpj.length != 18 && vidroscnpj.length != 0) {
      toast.error("Campo CNPJ invalido.")
    } else {
      setIsSearchingCnpj(true)

      async function postJSON(data) {
        try {
          const response = await fetch(
            `https://api.virtual.brasilbeneficios.club/api/landing/company?cnpj=${vidroscnpj.replace(
              /[.\-\/]/g,
              ""
            )}`,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )

          const result = await response.json()
          setvidrosEmail(result.email)
          setvidrosSolicitante(result.name)
          setvidrosTelefone(result.phone)
        } catch (error) {
          toast.error("CNPJ invalido ou empresa nao encontrada.")
        }
      }

      postJSON({ CPFCNPJ: vidroscnpj })

      setIsSearchingCnpj(false)
    }
  }

  return (
    <>
      {vidrosStatus === null ? (
        <Div className="fade-in col-lg-8">
          {keepLastVidros ? (
            <form
              onSubmit={handleVidrosSubmit}
              method="post"
              encType="multipart/form-data"
            >
              <input
                type="hidden"
                name="pid"
                value={localStorage.getItem("pid")}
              />
              <input
                type="hidden"
                name="eid"
                value={localStorage.getItem("eid")}
              />
              <Div className="row">
                <Div className="col-lg-6">
                  <div className="d-flex align-items-center gap-4">
                    <label className="form_field_label">CNPJ:</label>
                    {isSearchingCnpj && (
                      <div className="mb-2">
                        <Loading size="2px" />
                      </div>
                    )}
                  </div>
                  <InputMask
                    mask="99.999.999/9999-99"
                    maskChar=""
                    placeholder="__.___.___/____-__"
                    onChange={(e) => setvidrosCnpj(e.target.value)}
                    onBlur={handleCNPJ}
                    value={localStorage.getItem("cnpj")}
                  >
                    {(inputProps) => (
                      <input
                        {...inputProps}
                        name="CNPJ"
                        className="cs-form_field"
                      />
                    )}
                  </InputMask>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">Solicitante:</label>
                  <input
                    type="text"
                    onChange={(e) => setvidrosSolicitante(e.target.value)}
                    name="SolicitanteEmpresa"
                    value={localStorage.getItem("SolicitanteEmpresa")}
                    className="cs-form_field"
                  />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">Telefone:</label>
                  <InputMask
                    mask="(99) 9 9999-9999"
                    maskChar=""
                    placeholder="(__) _ ____-____"
                    onChange={(e) => setvidrosTelefone(e.target.value)}
                    value={localStorage.getItem("TelefoneEmpresa")}
                  >
                    {(inputProps) => (
                      <input
                        {...inputProps}
                        name="TelefoneEmpresa"
                        className="cs-form_field"
                      />
                    )}
                  </InputMask>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">E-mail:</label>
                  <input
                    type="email"
                    onChange={(e) => setvidrosEmail(e.target.value)}
                    name="EmailEmpresa"
                    value={localStorage.getItem("EmailEmpresa")}
                    className="cs-form_field"
                  />
                  <Spacing lg="20" md="20" />
                </Div>
              </Div>

              <Div className="row">
                <Div className="col-lg-6">
                  <label className="form_field_label">Nome do cliente:</label>
                  <input
                    type="text"
                    onChange={(e) => setvidrosnomesolicitante(e.target.value)}
                    name="Nome"
                    value={localStorage.getItem("Nome")}
                    className="cs-form_field"
                  />
                  <Spacing lg="20" md="20" />
                </Div>

                <Div className="col-lg-6">
                  <label className="form_field_label">CPF do cliente:</label>
                  <InputMask
                    mask="999.999.999-99"
                    maskChar=""
                    placeholder="___.___.___---"
                    onChange={(e) => setvidroscpfsolicitante(e.target.value)}
                    value={localStorage.getItem("CPF")}
                  >
                    {(inputProps) => (
                      <input
                        {...inputProps}
                        name="CPF"
                        className="cs-form_field"
                      />
                    )}
                  </InputMask>
                  <Spacing lg="20" md="20" />
                </Div>

                <Div className="col-lg-6">
                  <label className="form_field_label">E-mail do cliente:</label>
                  <input
                    type="email"
                    onChange={(e) => setvidrosemailsolicitante(e.target.value)}
                    name="Email"
                    value={localStorage.getItem("Email")}
                    className="cs-form_field"
                  />
                  <Spacing lg="20" md="20" />
                </Div>

                <Div className="col-lg-6">
                  <label className="form_field_label">
                    Telefone do cliente:
                  </label>
                  <InputMask
                    mask="(99) 9 9999-9999"
                    maskChar=""
                    placeholder="(__) _ ____-____"
                    onChange={(e) =>
                      setvidrostelefonesolicitante(e.target.value)
                    }
                    value={localStorage.getItem("Telefone")}
                  >
                    {(inputProps) => (
                      <input
                        {...inputProps}
                        name="Telefone"
                        className="cs-form_field"
                      />
                    )}
                  </InputMask>
                  <Spacing lg="20" md="20" />
                </Div>
              </Div>

              <Div className="row">
                <Div className="col-lg-6">
                  <label className="form_field_label">Placa:</label>
                  <input
                    type="text"
                    onChange={(e) => setvidrosplaca(e.target.value)}
                    name="Placa"
                    value={localStorage.getItem("Placa")}
                    className="cs-form_field"
                  />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">Chassi:</label>
                  <input
                    type="text"
                    onChange={(e) => setvidroschassi(e.target.value)}
                    name="Chassi"
                    value={localStorage.getItem("Chassi")}
                    className="cs-form_field"
                  />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">Tipo:</label>
                  <select
                    className="cs-form_field"
                    onChange={(e) => setvidrostipo(e.target.value)}
                    name="Tipo"
                  >
                    <option value="">Selecione uma opcao</option>
                    <option
                      value="Automovel"
                      selected={
                        localStorage.getItem("Tipo") === "Automovel"
                          ? true
                          : false
                      }
                    >
                      Automovel
                    </option>
                    <option
                      value="Caminhao"
                      selected={
                        localStorage.getItem("Tipo") === "Caminhao"
                          ? true
                          : false
                      }
                    >
                      Caminhao
                    </option>
                    <option
                      value="Onibus"
                      selected={
                        localStorage.getItem("Tipo") === "Onibus" ? true : false
                      }
                    >
                      Onibus
                    </option>
                  </select>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">Marca do veículo:</label>
                  <select
                    onChange={(e) => handleMarcaChange(e)}
                    className="cs-form_field"
                    name="Fabricante"
                  >
                    <option value="">Selecione uma marca</option>
                    {vidrosmarca.map((marca) => {
                      return (
                        <option
                          key={marca.Value}
                          value={marca.Value}
                          selected={
                            localStorage.getItem("Fabricante") === marca.Value
                              ? true
                              : false
                          }
                        >
                          {marca.Label}
                        </option>
                      )
                    })}
                  </select>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <div className="d-flex align-items-center gap-4">
                    <label className="form_field_label">
                      Modelo do veículo:
                    </label>
                    {isSearchingVehicule && (
                      <div className="mb-2">
                        <Loading size="2px" />
                      </div>
                    )}
                  </div>
                  <select
                    className="cs-form_field"
                    name="modelo"
                    disabled={isSearchingVehicule ? true : false}
                  >
                    <option value="">Selecione um modelo</option>
                    {vidrosmodelo.map((modelo) => {
                      return (
                        <option
                          key={modelo.Value}
                          value={modelo.Value}
                          selected={
                            localStorage.getItem("modelo") === modelo.Value
                              ? true
                              : false
                          }
                        >
                          {modelo.Label}
                        </option>
                      )
                    })}
                  </select>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">Ano Fabricacao:</label>
                  <input
                    type="text"
                    onChange={(e) => setvidrosanofabricacao(e.target.value)}
                    name="AnoFabricacao"
                    value={localStorage.getItem("AnoFabricacao")}
                    className="cs-form_field"
                  />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">Ano Modelo:</label>
                  <input
                    type="text"
                    onChange={(e) => setvidrosanomodelo(e.target.value)}
                    value={localStorage.getItem("AnoModelo")}
                    name="AnoModelo"
                    className="cs-form_field"
                  />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">Estado:</label>
                  <select
                    onChange={(e) => handleStateChange(e)}
                    className="cs-form_field"
                    name="Estado"
                  >
                    <option value="">Selecione um Estado</option>
                    {vidrosestado.map((state) => {
                      return (
                        <option
                          key={state.id}
                          value={state.sigla}
                          selected={
                            localStorage.getItem("Estado") === state.Value
                              ? true
                              : false
                          }
                        >
                          {state.nome}
                        </option>
                      )
                    })}
                  </select>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <div className="d-flex align-items-center gap-4">
                    <label className="form_field_label">Cidade:</label>
                    {isSearchingCity && (
                      <div className="mb-2">
                        <Loading size="2px" />
                      </div>
                    )}
                  </div>
                  <select
                    onChange={(e) => null}
                    name="Cidade"
                    disabled={isSearchingCity ? true : false}
                    className="cs-form_field"
                  >
                    <option value="">Selecione uma Cidade</option>
                    {vidroscidade.map((city) => {
                      return (
                        <option
                          key={city.id}
                          value={city.sigla}
                          selected={
                            localStorage.getItem("Cidade") === city.Value
                              ? true
                              : false
                          }
                        >
                          {city.nome}
                        </option>
                      )
                    })}
                  </select>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">Peça Danificada:</label>
                  <select
                    className="cs-form_field"
                    onChange={(e) => setvidrospecadanificada(e.target.value)}
                    name="Peca"
                  >
                    <option value="">Selecione uma opcao</option>
                    <option
                      value="Vidro Traseiro"
                      selected={
                        localStorage.getItem("Peca") === ">Vidro Traseiro"
                          ? true
                          : false
                      }
                    >
                      Vidro Traseiro
                    </option>
                    <option
                      value="Retrovisor"
                      selected={
                        localStorage.getItem("Peca") === "Retrovisor"
                          ? true
                          : false
                      }
                    >
                      Retrovisor
                    </option>
                    <option
                      value="ParaBrisa"
                      selected={
                        localStorage.getItem("Peca") === "ParaBrisa"
                          ? true
                          : false
                      }
                    >
                      ParaBrisa
                    </option>
                    <option
                      value="Janelas Laterais"
                      selected={
                        localStorage.getItem("Peca") === "Janelas Laterais"
                          ? true
                          : false
                      }
                    >
                      Janelas Laterais
                    </option>
                    <option
                      value="Farois"
                      selected={
                        localStorage.getItem("Peca") === "Farois" ? true : false
                      }
                    >
                      Farois
                    </option>
                    <option
                      value="Lanterna traseira"
                      selected={
                        localStorage.getItem("Peca") === "Lanterna traseira"
                          ? true
                          : false
                      }
                    >
                      Lanterna traseira
                    </option>
                  </select>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">Possui sensor?</label>
                  <select
                    className="cs-form_field"
                    onChange={(e) => setvidrospossuisensor(e.target.value)}
                    name="Sensor"
                  >
                    <option value="">Selecione uma opcao</option>
                    <option
                      value="Sim"
                      selected={
                        localStorage.getItem("Sensor") === "Sim" ? true : false
                      }
                    >
                      Sim
                    </option>
                    <option
                      value="Nao"
                      selected={
                        localStorage.getItem("Sensor") === "Nao" ? true : false
                      }
                    >
                      Nao
                    </option>
                  </select>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">Modelo de vidro:</label>
                  <select
                    className="cs-form_field"
                    onChange={(e) => setvidrosmodelovidro(e.target.value)}
                    name="ModeloVidro"
                  >
                    <option value="">Selecione uma opcao</option>
                    <option
                      value="Simples"
                      selected={
                        localStorage.getItem("ModeloVidro") === "Simples"
                          ? true
                          : false
                      }
                    >
                      Simples
                    </option>
                    <option
                      value="Grade"
                      selected={
                        localStorage.getItem("ModeloVidro") === "Grade"
                          ? true
                          : false
                      }
                    >
                      Grade
                    </option>
                  </select>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">
                    Descricao do sinistro:
                  </label>
                  <textarea
                    onChange={(e) => setvidrosdescricaosinistro(e.target.value)}
                    name="DescricaoSinistro"
                    value={localStorage.getItem("DescricaoSinistro")}
                    className="cs-form_field"
                    cols="30"
                    rows="3"
                  ></textarea>
                  <Spacing lg="20" md="20" />
                </Div>
              </Div>

              <Div className="row">
                <Div className="col-lg-6">
                  <label className="form_field_label">
                    Aviso de Sinistro da Empresa:
                  </label>
                  <FileInputPreview
                    file={vidrosavisodesinistro}
                    name="Anexo1"
                    setFile={setvidrosavisodesinistro}
                  />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">
                    Fotos do Vidro Danificado:
                  </label>
                  <FileInputPreview
                    file={vidrosfotosvidro}
                    name="Anexo2"
                    setFile={setvidrosfotosvidro}
                  />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">
                    Fotos da Vistoria Prévia do Veículo:
                  </label>
                  <FileInputPreview
                    file={vidrosfotovistoriaprevia}
                    name="Anexo3"
                    setFile={setvidrosfotovistoriaprevia}
                  />
                  <Spacing lg="20" md="20" />
                </Div>
              </Div>

              <button
                className="fade-in cs-btn cs-style1 cs-type1"
                type="submit"
              >
                <span>{sendingButton}</span>
              </button>
            </form>
          ) : (
            <form
              onSubmit={handleVidrosSubmit}
              method="post"
              encType="multipart/form-data"
            >
              <input type="hidden" name="IDEmpresa" value={eidField} />
              <Div className="row">
                <Div className="col-lg-6">
                  <div className="d-flex align-items-center gap-4">
                    <label className="form_field_label">CNPJ:</label>
                    {isSearchingCnpj && (
                      <div className="mb-2">
                        <Loading size="2px" />
                      </div>
                    )}
                  </div>
                  <InputMask
                    mask="99.999.999/9999-99"
                    maskChar=""
                    placeholder="__.___.___/____-__"
                    onChange={(e) => setvidrosCnpj(e.target.value)}
                    onBlur={handleCNPJ}
                  >
                    {(inputProps) => (
                      <input
                        {...inputProps}
                        name="CNPJ"
                        className="cs-form_field"
                      />
                    )}
                  </InputMask>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">Solicitante:</label>
                  <input
                    type="text"
                    onChange={(e) => setvidrosSolicitante(e.target.value)}
                    name="SolicitanteEmpresa"
                    value={vidrossolicitante ?? ""}
                    className="cs-form_field"
                  />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">Telefone:</label>
                  <InputMask
                    mask="(99) 9 9999-9999"
                    maskChar=""
                    placeholder="(__) _ ____-____"
                    onChange={(e) => setvidrosTelefone(e.target.value)}
                    value={vidrostelefone ?? ""}
                  >
                    {(inputProps) => (
                      <input
                        {...inputProps}
                        name="TelefoneEmpresa"
                        className="cs-form_field"
                      />
                    )}
                  </InputMask>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">E-mail:</label>
                  <input
                    type="email"
                    onChange={(e) => setvidrosEmail(e.target.value)}
                    name="EmailEmpresa"
                    value={vidrosemail ?? ""}
                    className="cs-form_field"
                  />
                  <Spacing lg="20" md="20" />
                </Div>
              </Div>

              {vidrosemail && (
                <Div className="row">
                  <Div className="col-lg-6">
                    <label className="form_field_label">Nome do cliente:</label>
                    <input
                      type="text"
                      onChange={(e) => setvidrosnomesolicitante(e.target.value)}
                      name="Nome"
                      value={vidrosnomesolicitante ?? ""}
                      className="cs-form_field"
                    />
                    <Spacing lg="20" md="20" />
                  </Div>

                  <Div className="col-lg-6">
                    <label className="form_field_label">CPF do cliente:</label>
                    <InputMask
                      mask="999.999.999-99"
                      maskChar=""
                      placeholder="___.___.___---"
                      onChange={(e) => setvidroscpfsolicitante(e.target.value)}
                      value={vidroscpfsolicitante ?? ""}
                    >
                      {(inputProps) => (
                        <input
                          {...inputProps}
                          name="CPF"
                          className="cs-form_field"
                        />
                      )}
                    </InputMask>
                    <Spacing lg="20" md="20" />
                  </Div>

                  <Div className="col-lg-6">
                    <label className="form_field_label">
                      E-mail do cliente:
                    </label>
                    <input
                      type="email"
                      onChange={(e) =>
                        setvidrosemailsolicitante(e.target.value)
                      }
                      name="Email"
                      value={vidrosemailsolicitante ?? ""}
                      className="cs-form_field"
                    />
                    <Spacing lg="20" md="20" />
                  </Div>

                  <Div className="col-lg-6">
                    <label className="form_field_label">
                      Telefone do cliente:
                    </label>
                    <InputMask
                      mask="(99) 9 9999-9999"
                      maskChar=""
                      placeholder="(__) _ ____-____"
                      onChange={(e) =>
                        setvidrostelefonesolicitante(e.target.value)
                      }
                      value={vidrostelefonesolicitante ?? ""}
                    >
                      {(inputProps) => (
                        <input
                          {...inputProps}
                          name="Telefone"
                          className="cs-form_field"
                        />
                      )}
                    </InputMask>
                    <Spacing lg="20" md="20" />
                  </Div>
                </Div>
              )}

              {vidrostelefonesolicitante && (
                <Div className="row">
                  <Div className="col-lg-6">
                    <label className="form_field_label">Placa:</label>
                    <input
                      type="text"
                      onChange={(e) => setvidrosplaca(e.target.value)}
                      name="Placa"
                      value={vidrosplaca ?? ""}
                      className="cs-form_field"
                    />
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-lg-6">
                    <label className="form_field_label">Chassi:</label>
                    <input
                      type="text"
                      onChange={(e) => setvidroschassi(e.target.value)}
                      name="Chassi"
                      value={vidroschassi ?? ""}
                      className="cs-form_field"
                    />
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-lg-6">
                    <label className="form_field_label">Tipo:</label>
                    <select
                      className="cs-form_field"
                      onChange={(e) => setvidrostipo(e.target.value)}
                      name="Tipo"
                    >
                      <option value="">Selecione uma opcao</option>
                      <option value="Automóvel">Automovel</option>
                      <option value="Caminhão">Caminhao</option>
                      <option value="Ônibus">Onibus</option>
                    </select>
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-lg-6">
                    <label className="form_field_label">
                      Marca do veículo:
                    </label>
                    <select
                      onChange={(e) => handleMarcaChange(e)}
                      className="cs-form_field"
                      name="Fabricante"
                    >
                      <option value="">Selecione uma marca</option>
                      {vidrosmarca.map((marca) => {
                        return (
                          <option key={marca.Value} value={marca.Value}>
                            {marca.Label}
                          </option>
                        )
                      })}
                    </select>
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-lg-6">
                    <div className="d-flex align-items-center gap-4">
                      <label className="form_field_label">
                        Modelo do veículo:
                      </label>
                      {isSearchingVehicule && (
                        <div className="mb-2">
                          <Loading size="2px" />
                        </div>
                      )}
                    </div>
                    <select
                      className="cs-form_field"
                      name="modelo"
                      onChange={handleModeloChange}
                      disabled={isSearchingVehicule ? true : false}
                    >
                      <option value="">Selecione um modelo</option>
                      {vidrosmodelo.map((modelo) => {
                        return (
                          <option key={modelo.Value} value={modelo.Value}>
                            {modelo.Label}
                          </option>
                        )
                      })}
                    </select>
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-lg-6">
                    <label className="form_field_label">Ano Fabricacao:</label>
                    <input
                      type="text"
                      onChange={(e) => setvidrosanofabricacao(e.target.value)}
                      name="AnoFabricacao"
                      value={vidrosanofabricacao ?? ""}
                      className="cs-form_field"
                    />
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-lg-6">
                    <label className="form_field_label">Ano Modelo:</label>
                    <input
                      type="text"
                      onChange={(e) => setvidrosanomodelo(e.target.value)}
                      value={vidrosanomodelo ?? ""}
                      name="AnoModelo"
                      className="cs-form_field"
                    />
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-lg-6">
                    <label className="form_field_label">Estado:</label>
                    <select
                      onChange={(e) => handleStateChange(e)}
                      className="cs-form_field"
                      name="Estado"
                    >
                      <option value="">Selecione um Estado</option>
                      {vidrosestado.map((state) => {
                        return (
                          <option key={state.id} value={state.sigla}>
                            {state.nome}
                          </option>
                        )
                      })}
                    </select>
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-lg-6">
                    <div className="d-flex align-items-center gap-4">
                      <label className="form_field_label">Cidade:</label>
                      {isSearchingCity && (
                        <div className="mb-2">
                          <Loading size="2px" />
                        </div>
                      )}
                    </div>
                    <select
                      onChange={(e) => null}
                      name="Cidade"
                      disabled={isSearchingCity ? true : false}
                      className="cs-form_field"
                    >
                      <option value="">Selecione uma Cidade</option>
                      {vidroscidade.map((city) => {
                        return (
                          <option key={city.id} value={city.sigla}>
                            {city.nome}
                          </option>
                        )
                      })}
                    </select>
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-lg-6">
                    <label className="form_field_label">Peça Danificada:</label>
                    <select
                      className="cs-form_field"
                      onChange={(e) => setvidrospecadanificada(e.target.value)}
                      name="Peca"
                    >
                      <option value="">Selecione uma opcao</option>
                      <option value="Vidro Traseiro">Vidro Traseiro</option>
                      <option value="Retrovisor">Retrovisor</option>
                      <option value="ParaBrisa">ParaBrisa</option>
                      <option value="Janelas Laterais">Janelas Laterais</option>
                      <option value="Farois">Farois</option>
                      <option value="Lanterna traseira">
                        Lanterna traseira
                      </option>
                    </select>
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-lg-6">
                    <label className="form_field_label">Possui sensor?</label>
                    <select
                      className="cs-form_field"
                      onChange={(e) => setvidrospossuisensor(e.target.value)}
                      name="Sensor"
                    >
                      <option value="">Selecione uma opcao</option>
                      <option value="Sim">Sim</option>
                      <option value="Nao">Nao</option>
                    </select>
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-lg-6">
                    <label className="form_field_label">Modelo de vidro:</label>
                    <select
                      className="cs-form_field"
                      onChange={(e) => setvidrosmodelovidro(e.target.value)}
                      name="ModeloVidro"
                    >
                      <option value="">Selecione uma opcao</option>
                      <option value="Simples">Simples</option>
                      <option value="Grade">Grade</option>
                    </select>
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-lg-6">
                    <label className="form_field_label">
                      Descricao do sinistro:
                    </label>
                    <textarea
                      onChange={(e) =>
                        setvidrosdescricaosinistro(e.target.value)
                      }
                      name="DescricaoSinistro"
                      value={vidrosdescricaosinistro ?? ""}
                      className="cs-form_field"
                      cols="30"
                      rows="3"
                    ></textarea>
                    <Spacing lg="20" md="20" />
                  </Div>
                </Div>
              )}

              {vidrosdescricaosinistro && (
                <Div className="row">
                  <Div className="col-lg-6">
                    <label className="form_field_label">
                      Aviso de Sinistro da Empresa:
                    </label>
                    <FileInputPreview
                      file={vidrosavisodesinistro}
                      name="Anexo1"
                      setFile={setvidrosavisodesinistro}
                    />
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-lg-6">
                    <label className="form_field_label">
                      Foto do Vidro Danificado:
                    </label>
                    <FileInputPreview
                      file={vidrosfotosvidro}
                      name="Anexo2"
                      setFile={setvidrosfotosvidro}
                    />
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-lg-6">
                    <label className="form_field_label">
                      Foto da Vistoria Prévia do Veículo:
                    </label>
                    <FileInputPreview
                      file={vidrosfotovistoriaprevia}
                      name="Anexo3"
                      setFile={setvidrosfotovistoriaprevia}
                    />
                    <Spacing lg="20" md="20" />
                  </Div>
                </Div>
              )}

              {vidrosfotovistoriaprevia && (
                <button
                  className="fade-in cs-btn cs-style1 cs-type1"
                  type="submit"
                >
                  <span>{sendingButton}</span>
                </button>
              )}
            </form>
          )}
        </Div>
      ) : (
        <Div>
          <CheckCircle size={50} />
          <h5 className="mt-3">Sucesso!</h5>
          <p>
            Sua solicitação foi realizada com sucesso!
            <br />
            Em até 48 horas entraremos em contato para fazer a liberação do seu
            benefício!
          </p>
          <button
            className="fade-in cs-btn cs-style1 cs-type1"
            type="button"
            onClick={(e) => window.location.reload()}
          >
            <span>Realizar outra solicitacao</span>
          </button>
        </Div>
      )}
    </>
  )
}
