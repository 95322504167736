import { useState } from "react";

export function useFetchCities() {
  const [cities, setCities] = useState([]);
  const [isSearchingCity, setIsSearchingCity] = useState(false);

  const fetchCities = async (stateCode) => {
    setIsSearchingCity(true);
    try {
      const response = await fetch(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateCode}/municipios`
      );
      const data = await response.json();
      setCities(data);
    } catch (error) {
      console.error("Erro ao buscar cidades:", error);
    } finally {
      setIsSearchingCity(false);
    }
  };

  return { cities, fetchCities, isSearchingCity };
}
