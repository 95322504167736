import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from "../Header";
import { SolicitationStorageContext } from "../CheckPreviousSolicitationContext";
import { SlideProvider } from "../SlideContext";

export default function Layout({ headerVariant }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header variant={headerVariant} />
      <SlideProvider>
        <SolicitationStorageContext>
          <Outlet />
        </SolicitationStorageContext>
      </SlideProvider>
    </>
  );
}
