import { useState } from "react";

export function useFetchStates() {
  const [states, setStates] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const fetchStates = async () => {
    setIsFetching(true);
    try {
      const response = await fetch(
        "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
      );
      const data = await response.json();
      setStates(data);
    } catch (error) {
      console.error("Erro ao buscar estados:", error);
    } finally {
      setIsFetching(false);
    }
  };

  return { states, fetchStates, isFetching };
}
